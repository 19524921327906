import { useQuery } from 'villus';
import { AttributesDataDocument, type AttributesDataQuery } from '~/graphql/Attributes';

interface AttributeComposableOptions {
  localize?: string[];
}

export async function useAttributes(opts?: AttributeComposableOptions): Promise<{
  attributes: Ref<NonNullable<AttributesDataQuery['AttributesData']>['items']>;
}> {
  const localizeKeys = opts?.localize ?? [];

  const { cacheParam } = useSetLocaleToCacheParams();
  const { t } = useI18n();

  const attributes = useCachedSsrRef<NonNullable<AttributesDataQuery['AttributesData']>['items']>(
    cacheParam('attributes'),
    [],
  );

  const { execute } = useQuery({
    query: AttributesDataDocument.toString(),
    fetchOnMount: false,
  });

  // Don't refetch categories unless cache expired
  if (attributes.value?.length) {
    return { attributes };
  }

  const { data, error } = await execute();

  if (error) {
    throw new Error(error.message);
  }

  attributes.value = toNonNullable(data?.AttributesData?.items).map(attribute => {
    if (attribute.attribute_code && localizeKeys.includes(attribute.attribute_code)) {
      return {
        ...attribute,
        attribute_options: attribute.attribute_options?.map(option => ({
          ...option,
          label: t(`aggregation.${attribute.attribute_code}`, [option?.label]).toString(),
        })),
      };
    }
    return attribute;
  });

  return {
    attributes,
  };
}
